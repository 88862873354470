import { QuestionAttemptRead as OrvalQuestionAttemptRead } from 'studyvibes-api/orval/models';

import * as zod from 'zod';

export const QuestionAttemptRead = zod.object({
  isCorrect: zod.boolean().optional(),
  isMark: zod.boolean().optional(),
  isSolved: zod.boolean().optional(),
});

export type QuestionAttemptRead = zod.infer<typeof QuestionAttemptRead>;

export const toQuestionAttemptRead = (
  questionAttempt: OrvalQuestionAttemptRead,
): QuestionAttemptRead => {
  let result;
  try {
    result = QuestionAttemptRead.parse({
      isCorrect: questionAttempt?.is_correct,
      isMark: questionAttempt?.is_mark,
      isSolved: questionAttempt?.is_solved,
    });
  } catch (e) {
    console.log('Error parsing question attempt read', e);
    throw e;
  }

  return result;
};
