import { SubtopicCount as OrvalSubtopicCount } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

import { StatusCount, toStatusCount } from './status-counts';

export const SubtopicCount = zod.object({
  id: zod.number(),
  name: zod.string(),
  order: zod.number(),
  totalQuestions: zod.number(),
  statusCounts: StatusCount.optional(),
});

export type SubtopicCount = zod.infer<typeof SubtopicCount>;

export const toSubtopicCount = (subtopicCount: OrvalSubtopicCount): SubtopicCount => {
  let result;

  const statusCounts = toStatusCount(subtopicCount.status_counts);

  try {
    result = SubtopicCount.parse({
      id: subtopicCount.id,
      name: subtopicCount.name,
      order: subtopicCount.order,
      totalQuestions: subtopicCount?.total_questions || 0,
      statusCounts,
    });
  } catch (e) {
    console.log('Error parsing subtopic count', e);
    throw e;
  }

  return result;
};
