import { QuestionAttempt as OrvalQuestionAttempt } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const QuestionAttempt = zod.object({
  id: zod.number(),
  isCorrect: zod.boolean().optional(),
  isMark: zod.boolean().optional(),
  isSolved: zod.boolean().optional(),
  questionId: zod.number(),
  createdAt: zod.date(),
  updatedAt: zod.date(),
});

export type QuestionAttempt = zod.infer<typeof QuestionAttempt>;

export const toQuestionAttempt = (course: OrvalQuestionAttempt): QuestionAttempt => {
  let result;
  try {
    result = QuestionAttempt.parse({
      id: course.id,
      isCorrect: course.is_correct,
      isMark: course.is_mark,
      isSolved: course.is_solved,
      questionId: course.question,
      createdAt: new Date(course.created_at),
      updatedAt: new Date(course.updated_at),
    });
  } catch (e) {
    console.log('Error question attempt', e);
    throw e;
  }

  return result;
};
