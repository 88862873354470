/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `pending` - Pending
 * `rejected` - Rejected
 * `approved` - Approved
 * `in progress` - In Progress
 * `completed` - Completed
 */
export type StatusEnum = (typeof StatusEnum)[keyof typeof StatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusEnum = {
  pending: 'pending',
  rejected: 'rejected',
  approved: 'approved',
  in_progress: 'in progress',
  completed: 'completed',
} as const;
