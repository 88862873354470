import { GroupedCourse as OrvalGroupedCourse } from 'studyvibes-api/orval/models';
import * as zod from 'zod';
import { GroupedCourseDetail, toGroupedCourseDetail } from './grouped-course-detail';

export const GroupedCourse = zod.object({
  courseName: zod.string(),
  details: zod.array(GroupedCourseDetail),
});

export type GroupedCourse = zod.infer<typeof GroupedCourse>;

export const toGroupedCourse = (course: OrvalGroupedCourse): GroupedCourse => {
  let result;

  try {
    result = GroupedCourse.parse({
      courseName: course.course_name,
      details: course.details.map(toGroupedCourseDetail),
    });
  } catch (e) {
    console.log('Error parsing grouped course', e);
    throw e;
  }

  return result;
};
