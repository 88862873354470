import { TopicCount as OrvalTopicCount } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

import { SubtopicCount, toSubtopicCount } from './subtopics-count';

export const TopicCount = zod.object({
  id: zod.number(),
  name: zod.string(),
  totalQuestions: zod.number(),
  subtopics: zod.array(SubtopicCount).optional(),
});

export type TopicCount = zod.infer<typeof TopicCount>;

export const toTopicCount = (topicCount: OrvalTopicCount): TopicCount => {
  let result;

  const subtopics = topicCount.subtopics?.map(toSubtopicCount);

  try {
    result = TopicCount.parse({
      id: topicCount.id,
      name: topicCount.name,
      totalQuestions: topicCount?.total_questions || 0,
      subtopics,
    });
  } catch (e) {
    console.log('Error parsing topic count', e);
    throw e;
  }

  return result;
};
