/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

export type UsersTutorProfileListTutorType =
  (typeof UsersTutorProfileListTutorType)[keyof typeof UsersTutorProfileListTutorType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsersTutorProfileListTutorType = {
  Coach: 'Coach',
  Master: 'Master',
  Specialist: 'Specialist',
} as const;
