import { QuestionBankQuestionListParams as OrvalQuestionBankQuestionListParams } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const QuestionListParams = zod.object({
  difficulty: zod.string().optional(),
  isCorrect: zod.boolean().optional(),
  isMark: zod.boolean().optional(),
  isSolved: zod.boolean().optional(),
  isCal: zod.boolean().optional(),
  limit: zod.number().optional(),
  offset: zod.number().optional(),
  subtopicId: zod.number().optional(),
  topicId: zod.number().optional(),
});

export type QuestionListParams = zod.infer<typeof QuestionListParams>;

export const toQuestionListParams = (
  questionListParams: QuestionListParams,
): OrvalQuestionBankQuestionListParams => {
  try {
    const parsedParams = QuestionListParams.parse(questionListParams);
    return {
      difficulty: parsedParams.difficulty,
      is_correct: parsedParams.isCorrect,
      is_mark: parsedParams.isMark,
      is_solved: parsedParams.isSolved,
      limit: parsedParams.limit,
      offset: parsedParams.offset,
      subtopic_id: parsedParams.subtopicId,
      topic_id: parsedParams.topicId,
      is_cal: parsedParams.isCal,
    };
  } catch (e) {
    console.log('Error parsing question list params', e);
    throw e;
  }
};
