import { StatusCounts as OrvalStatusCounts } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const StatusCount = zod.object({
  isCorrect: zod.number(),
  isMarked: zod.number(),
  isSolved: zod.number(),
});

export type StatusCounts = zod.infer<typeof StatusCount>;

export const toStatusCount = (statusCounts: OrvalStatusCounts): StatusCounts => {
  let result;
  try {
    result = StatusCount.parse({
      isCorrect: statusCounts?.is_correct || 0,
      isMarked: statusCounts?.is_marked || 0,
      isSolved: statusCounts?.is_solved || 0,
    });
  } catch (e) {
    console.log('Error parsing status counts', e);
    throw e;
  }

  return result;
};
