/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

export type QuestionBankQuestionListDifficulty =
  (typeof QuestionBankQuestionListDifficulty)[keyof typeof QuestionBankQuestionListDifficulty];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionBankQuestionListDifficulty = {
  EASY: 'EASY',
  HARD: 'HARD',
  MEDIUM: 'MEDIUM',
} as const;
