import { CourseRead as OrvalCourseRead } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const CourseRead = zod.object({
  id: zod.number(),
  name: zod.string(),
  level: zod.string(),
});

export type CourseRead = zod.infer<typeof CourseRead>;
export const toCourseRead = (courseRead: OrvalCourseRead): CourseRead => {
  let result;

  try {
    result = CourseRead.parse({
      id: courseRead.id,
      name: courseRead.name,
      level: courseRead.level,
    });
  } catch (e) {
    console.log('Error parsing course read', e);
    throw e;
  }

  return result;
};
