import { cva } from 'class-variance-authority';

export const wrapperStyle = cva(['flex items-center justify-center gap-2 !appearance-none'], {
  variants: {
    size: {
      s: 'px-3 py-2 !text-b2b',
      m: 'px-4 py-2.5 !text-b1b',
      lg: 'px-5 py-3 !text-b1b',
      xlg: 'px-6 py-4 !text-b1b',
    },
    shape: {
      square: 'rounded-s',
      circle: 'rounded-full',
    },
    iconOnly: {
      true: '',
      false: 'w-fit h-fit',
    },
    variant: {
      primary:
        'bg-bg-primary text-content-white hover:outline hover:outline-4 hover:outline-border-secondary active:outline active:outline-4 active:outline-border-secondary active:opacity-buttonFocused disabled:opacity-buttonDisabled disabled:outline-none',
      'primary-light':
        'bg-bg-secondary text-content-secondary hover:outline hover:outline-4 hover:outline-border-secondary active:outline active:outline-4 active:outline-border-secondary active:opacity-buttonFocused disabled:opacity-buttonDisabled disabled:outline-none disabled:bg-bg-secondary',
      'primary-white':
        'bg-bg-white text-content-secondary hover:outline hover:outline-4 hover:outline-border-secondary active:outline active:outline-4 active:outline-border-secondary active:opacity-buttonFocused disabled:opacity-buttonDisabled disabled:outline-none disabled:bg-bg-white',
      secondary:
        'bg-bg-tertiary text-content-white hover:outline hover:outline-4 hover:outline-border-quaternary active:outline active:outline-4 active:outline-border-quaternary active:opacity-buttonFocused disabled:opacity-buttonDisabled disabled:outline-none',
      'secondary-light':
        'bg-bg-quaternary text-content-quaternary hover:outline hover:outline-4 hover:outline-border-quaternary active:outline active:outline-4 active:outline-border-quaternary active:opacity-buttonFocused disabled:opacity-buttonDisabled disabled:outline-none disabled:bg-bg-quaternary',
      'secondary-white':
        'bg-bg-white text-content-quaternary hover:outline hover:outline-4 hover:outline-border-quaternary active:outline active:outline-4 active:outline-border-quaternary active:opacity-buttonFocused disabled:opacity-buttonDisabled disabled:outline-none disabled:bg-bg-white',
      tertiary:
        'bg-bg-inversePrimary text-content-white hover:outline hover:outline-4 hover:outline-border-inverseTertiary active:outline active:outline-4 active:outline-border-inverseTertiary active:opacity-buttonFocused disabled:opacity-buttonDisabled disabled:outline-none',
      'tertiary-light':
        'bg-bg-inverseSecondary text-content-inversePrimary hover:outline hover:outline-4 hover:outline-border-inverseTertiary active:outline active:outline-4 active:outline-border-inverseTertiary active:opacity-buttonFocused disabled:opacity-buttonDisabled disabled:outline-none disabled:bg-bg-inverseSecondary',
      'tertiary-white':
        'bg-bg-white text-content-inversePrimary hover:outline hover:outline-4 hover:outline-border-inverseTertiary active:outline active:outline-4 active:outline-border-inverseTertiary active:opacity-buttonFocused disabled:opacity-buttonDisabled disabled:outline-none disabled:bg-bg-white',
      'tertiary-transparent':
        'bg-bg-transparent text-content-inversePrimary hover:outline hover:outline-4 hover:outline-border-inverseTertiary active:outline active:outline-4 active:outline-border-inverseTertiary active:opacity-buttonFocused disabled:opacity-buttonDisabled disabled:outline-none disabled:bg-transparent',
    },
  },
  compoundVariants: [
    { iconOnly: true, size: 's', className: 'w-9 h-9' },
    { iconOnly: true, size: 'm', className: 'w-10 h-10' },
    { iconOnly: true, size: 'lg', className: 'w-[48px] h-[48px]' },
    { iconOnly: true, size: 'xlg', className: 'w-[56px] h-[56px]' },
  ],
  defaultVariants: {
    size: 's',
    shape: 'square',
    variant: 'primary',
    iconOnly: false,
  },
});
