/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `Student` - Student
 * `Tutor` - Tutor
 * `Staff` - Staff
 */
export type RoleEnum = (typeof RoleEnum)[keyof typeof RoleEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleEnum = {
  Student: 'Student',
  Tutor: 'Tutor',
  Staff: 'Staff',
} as const;
