import React from 'react';

import { cnm } from '@/utils/cnm';

import { TypographyProps } from './types';

function Text({ as = 'span', className, children, ...props }: TypographyProps) {
  const component = React.createElement(as, { className, ...props }, children);

  return component;
}

function createTypographyComponent(
  typographyClass: string,
  defaultTag: keyof JSX.IntrinsicElements,
) {
  return function TypographyComponent({ as = defaultTag, className, ...props }: TypographyProps) {
    return <Text as={as} className={cnm(typographyClass, className)} {...props} />;
  };
}

// H1 관련 컴포넌트
export const H1b = createTypographyComponent('!text-h1b', 'h1');
export const H1sb = createTypographyComponent('!text-h1sb', 'h1');
export const H1 = createTypographyComponent('!text-h1', 'h1');

// H2 관련 컴포넌트
export const H2b = createTypographyComponent('!text-h2b', 'h2');
export const H2sb = createTypographyComponent('!text-h2sb', 'h2');
export const H2 = createTypographyComponent('!text-h2', 'h2');

// H3 관련 컴포넌트
export const H3b = createTypographyComponent('!text-h3b', 'h3');
export const H3sb = createTypographyComponent('!text-h3sb', 'h3');
export const H3 = createTypographyComponent('!text-h3', 'h3');

// H4 관련 컴포넌트
export const H4b = createTypographyComponent('!text-h4b', 'h4');
export const H4sb = createTypographyComponent('!text-h4sb', 'h4');
export const H4 = createTypographyComponent('!text-h4', 'h4');

// H5 관련 컴포넌트
export const H5b = createTypographyComponent('!text-h5b', 'h5');
export const H5sb = createTypographyComponent('!text-h5sb', 'h5');
export const H5 = createTypographyComponent('!text-h5', 'h5');

// H6 관련 컴포넌트
export const H6b = createTypographyComponent('!text-h6b', 'h6');
export const H6sb = createTypographyComponent('!text-h6sb', 'h6');
export const H6 = createTypographyComponent('!text-h6', 'h6');

// qbH6 컴포넌트
export const QbH6 = createTypographyComponent('!text-qbH6', 'h6');

// b1 관련 컴포넌트
export const B1b = createTypographyComponent('!text-b1b', 'span');
export const B1sb = createTypographyComponent('!text-b1sb', 'span');
export const B1 = createTypographyComponent('!text-b1', 'span');

// b2 관련 컴포넌트
export const B2b = createTypographyComponent('!text-b2b', 'span');
export const B2sb = createTypographyComponent('!text-b2sb', 'span');
export const B2 = createTypographyComponent('!text-b2', 'span');

// lb1 관련 컴포넌트
export const Lb1b = createTypographyComponent('!text-lb1b', 'span');
export const Lb1sb = createTypographyComponent('!text-lb1sb', 'span');
export const Lb1 = createTypographyComponent('!text-lb1', 'span');

// lb2 관련 컴포넌트
export const Lb2b = createTypographyComponent('!text-lb2b', 'span');
export const Lb2sb = createTypographyComponent('!text-lb2sb', 'span');
export const Lb2 = createTypographyComponent('!text-lb2', 'span');
