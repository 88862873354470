import { Subtopic as OrvalSubTopic } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const SubTopic = zod.object({
  id: zod.number(),
  name: zod.string(),
  description: zod.string().nullable(),
  createdAt: zod.date(),
  updatedAt: zod.date(),
  orderId: zod.number().optional(),
  topicId: zod.number().nullable(),
});

export type SubTopic = zod.infer<typeof SubTopic>;

export const toSubTopic = (subTopic: OrvalSubTopic): SubTopic => {
  let result;
  try {
    result = SubTopic.parse({
      id: subTopic.id,
      name: subTopic.name,
      description: subTopic.description,
      topicId: subTopic.topic,
      orderId: subTopic.order,
      createdAt: new Date(subTopic.created_at),
      updatedAt: new Date(subTopic.updated_at),
    });
  } catch (e) {
    console.log('Error parsing sub-topic', e);
    throw e;
  }

  return result;
};
