import { GroupedSubject as OrvalGroupedSubject } from 'studyvibes-api/orval/models';
import * as zod from 'zod';
import { GroupedCourse, toGroupedCourse } from './grouped-course';

export const GroupedSubject = zod.object({
  courses: zod.array(GroupedCourse),
  subjectId: zod.number(),
  subjectName: zod.string(),
});

export type GroupedSubject = zod.infer<typeof GroupedSubject>;

export const toGroupedSubject = (course: OrvalGroupedSubject): GroupedSubject => {
  let result;
  try {
    result = GroupedSubject.parse({
      courses: course.courses.map(toGroupedCourse),
      subjectId: course.subject_id,
      subjectName: course.subject_name,
    });
  } catch (e) {
    console.log('Error parsing grouped subject', e);
    throw e;
  }

  return result;
};
