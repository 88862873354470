/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `High` - High
 * `Middle` - Middle
 * `Low` - Low
 */
export type GradeLevelEnum = (typeof GradeLevelEnum)[keyof typeof GradeLevelEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GradeLevelEnum = {
  High: 'High',
  Middle: 'Middle',
  Low: 'Low',
} as const;
