import { Topic as OrvalTopic } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const Topic = zod.object({
  id: zod.number(),
  name: zod.string(),
  description: zod.string().nullable(),
  createdAt: zod.date(),
  updatedAt: zod.date(),
  courseId: zod.number().nullable(),
});

export type Topic = zod.infer<typeof Topic>;

export const toTopic = (topic: OrvalTopic): Topic => {
  let result;
  try {
    result = Topic.parse({
      id: topic.id,
      name: topic.name,
      description: topic.description,
      courseId: topic.course,
      createdAt: new Date(topic.created_at),
      updatedAt: new Date(topic.updated_at),
    });
  } catch (e) {
    console.log('Error parsing topic', e);
    throw e;
  }

  return result;
};
