import { cnm } from '@/utils/cnm';

import { wrapperStyle } from './styles';
import { ButtonProps } from './types';

export default function Button({
  children,
  size,
  variant,
  leadingIcon,
  trailingIcon,
  className,
  shape,
  type = 'button',
  iconOnly,
  ...props
}: ButtonProps) {
  return (
    <button
      className={cnm(wrapperStyle({ size, variant, shape, iconOnly }), className)}
      type={type}
      {...props}
    >
      {leadingIcon}
      {children}
      {trailingIcon}
    </button>
  );
}
