import { useInfiniteQuery } from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { toCourse } from 'entity-convert/entity/course';
import { toGroupedSubject } from 'entity-convert/entity/grouped-subject';
import { toQuestion } from 'entity-convert/entity/question';
import { toQuestionAttempt } from 'entity-convert/entity/question-attempt';
import {
  QuestionListParams,
  toQuestionListParams,
} from 'entity-convert/entity/question-list-params';
import { toQuestionRead } from 'entity-convert/entity/question-read';
import { toSubTopic } from 'entity-convert/entity/sub-topic';
import { toSubject } from 'entity-convert/entity/subject';
import { toQuestionStatusCount } from 'entity-convert/entity/subject-overview';
import { toTopic } from 'entity-convert/entity/topic';
import {
  getQuestionBankQuestionListQueryKey,
  questionBankQuestionList,
  useQuestionBankAttemptRetrieve,
  useQuestionBankCourseList,
  useQuestionBankCourseRetrieve,
  useQuestionBankGroupedSubjectsList,
  useQuestionBankQuestionList,
  useQuestionBankQuestionRetrieve,
  useQuestionBankQuestionStatusCountRetrieve,
  useQuestionBankSubjectList,
  useQuestionBankSubjectRetrieve,
  useQuestionBankSubtopicList,
  useQuestionBankSubtopicRetrieve,
  useQuestionBankTopicList,
  useQuestionBankTopicRetrieve,
} from 'studyvibes-api/orval/endpoints/studyVibesRESTAPI';

export const useGetSubjectList = ({ inService = true }: { inService: boolean }) => {
  return useQuestionBankSubjectList(
    { in_service: inService },
    {
      query: {
        select: (data) => data.map(toSubject),
      },
    },
  );
};

export const useGetSubject = (id: number, { inService = true }: { inService: boolean }) => {
  return useQuestionBankSubjectRetrieve(
    id,
    { in_service: inService },
    {
      query: {
        select: (data) => toSubject(data),
        enabled: Boolean(id),
      },
    },
  );
};

export const useGetCourseList = ({
  subjectId,
  inService = true,
}: {
  subjectId?: number;
  inService?: boolean;
}) => {
  return useQuestionBankCourseList(
    {
      subject: subjectId,
      in_service: inService,
    },
    {
      query: {
        select: (data) => data?.map(toCourse),
      },
    },
  );
};

export const useGetCourse = (id: number) => {
  return useQuestionBankCourseRetrieve(id, {
    query: {
      select: (data) => toCourse(data),
      enabled: Boolean(id),
    },
  });
};

export const useGetTopicList = (courseId?: number) => {
  return useQuestionBankTopicList(
    {
      course: courseId,
    },
    {
      query: {
        select: (data) => data.map(toTopic),
      },
    },
  );
};

export const useGetTopic = (id: number) => {
  return useQuestionBankTopicRetrieve(id, {
    query: {
      select: (data) => toTopic(data),
      enabled: Boolean(id),
    },
  });
};
export const useGetSubTopicList = (topicId: number) => {
  return useQuestionBankSubtopicList(
    {
      topic: topicId,
    },
    {
      query: {
        select: (data) => data.map(toSubTopic),
      },
    },
  );
};
export const useGetSubTopic = (id: number) => {
  return useQuestionBankSubtopicRetrieve(id, {
    query: {
      select: (data) => toSubTopic(data),
      enabled: Boolean(id),
    },
  });
};

export const useGetQuestionList = (params: QuestionListParams) => {
  return useQuestionBankQuestionList(toQuestionListParams(params), {
    query: {
      select: (data) => {
        const items = data.items.map(toQuestionRead);
        return { ...data, items };
      },
    },
  });
};

export const useGetInfinityQuestionList = (params: QuestionListParams) => {
  return useInfiniteQuery({
    queryKey: getQuestionBankQuestionListQueryKey(toQuestionListParams(params)),
    queryFn: ({ pageParam = 0 }) =>
      questionBankQuestionList({ ...toQuestionListParams(params), offset: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.paging.next,
    select: (data) => {
      const items = data.pages.flatMap((page) => page.items.map(toQuestionRead));
      return { ...data, items };
    },
  });
};

export const useGetQuestion = (id: number) => {
  return useQuestionBankQuestionRetrieve(id, {
    query: {
      select: toQuestion,
    },
  });
};

export const useGetQuestionStatusCount = (subjectId: number) => {
  return useQuestionBankQuestionStatusCountRetrieve(subjectId, {
    query: {
      select: (data) => toQuestionStatusCount(data),
    },
  });
};

export const useGetQuestionAttempt = (questionId: number) => {
  return useQuestionBankAttemptRetrieve(questionId, {
    query: {
      select: toQuestionAttempt,
      enabled: Boolean(questionId),
    },
  });
};

export const useGetGroupedSubjectList = ({ inService }: { inService: boolean }) => {
  return useQuestionBankGroupedSubjectsList(
    { in_service: inService },
    {
      query: {
        select: (data) => data.map(toGroupedSubject),
      },
    },
  );
};
