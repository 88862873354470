/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `ESSAY` - Essay
 * `MULTIPLE_CHOICE` - Multiple Choice
 * `SHORT_ANSWER` - Short Answer
 */
export type QuestionTypeEnum = (typeof QuestionTypeEnum)[keyof typeof QuestionTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionTypeEnum = {
  ESSAY: 'ESSAY',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  SHORT_ANSWER: 'SHORT_ANSWER',
} as const;
