/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

export type QuestionBankSubjectRetrieveInService =
  (typeof QuestionBankSubjectRetrieveInService)[keyof typeof QuestionBankSubjectRetrieveInService];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionBankSubjectRetrieveInService = {
  false: 'false',
  true: 'true',
} as const;
