import { QuestionRead as OrvalQuestionRead } from 'studyvibes-api/orval/models';
import { Question, toQuestion } from './question';
import { QuestionAttemptRead, toQuestionAttemptRead } from './question-attempt-read';
import * as zod from 'zod';

export const QuestionRead = zod.object({
  question: Question,
  attempt: QuestionAttemptRead.nullable(),
});

export type QuestionRead = zod.infer<typeof QuestionRead>;

export const toQuestionRead = (questionRead: OrvalQuestionRead): QuestionRead => {
  let result;

  try {
    result = QuestionRead.parse({
      question: toQuestion(questionRead.question),
      attempt: toQuestionAttemptRead(questionRead.attempt),
    });
  } catch (e) {
    console.log('Error parsing question read', e);
    throw e;
  }

  return result;
};
