/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `MALE` - Male
 * `FEMALE` - Female
 * `OTHER` - Other
 */
export type PreferredTutorGenderEnum =
  (typeof PreferredTutorGenderEnum)[keyof typeof PreferredTutorGenderEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PreferredTutorGenderEnum = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
} as const;
