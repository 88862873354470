import { PaymentPrevPage, TutoringMatchingStatus } from './types';

const roots = {
  home: '/',
  'sign-up': '/sign-up',
  'question-bank': '/question-bank',
  tutoring: '/tutoring',
  'my-account': '/my-account',
  complete: '/complete',
  payment: '/payment',
};

export const paths = {
  home: roots.home,
  signUp: {
    root: roots['sign-up'],
    plus: `${roots['sign-up']}/plus`,
  },
  questionBank: {
    root: ({ courseId }: { courseId: number }) => `${roots['question-bank']}?courseId=${courseId}`,
    questionList: ({
      course,
      topic,
      subTopic,
      subTopicId,
    }: {
      course: string;
      topic: string;
      subTopic: string;
      subTopicId: number;
    }) =>
      `${roots['question-bank']}/question-list?course=${course}&topic=${topic}&subTopic=${subTopic}&subTopicId=${subTopicId}`,
  },
  tutoring: {
    root: roots.tutoring,
  },
  myAccount: {
    root: `${roots['my-account']}/profile`,
    profile: `${roots['my-account']}/profile`,
    questionBank: {
      root: `${roots['my-account']}/questionbank`,
      subjects: (courseId: number) =>
        `${roots['my-account']}/questionbank/subjects?courseId=${courseId}`,
    },
    tutoring: {
      root: `${roots['my-account']}/tutoring`,
      bookingHistory: `${roots['my-account']}/tutoring/booking-history`,
      matching: (status: TutoringMatchingStatus) =>
        `${roots['my-account']}/tutoring/matching?status=${status}`,
      matchingDetail: (id: number) => `${roots['my-account']}/tutoring/matching/${id}`,
    },
    membership: {
      root: `${roots['my-account']}/membership`,
      paymentMethod: `${roots['my-account']}/membership/payment-method`,
    },
    billing: `${roots['my-account']}/billing`,
  },
  complete: roots.complete,
  payment: {
    root: (prevPage: PaymentPrevPage) => `/${prevPage}${roots.payment}`,
    success: (prevPage: PaymentPrevPage) => `/${prevPage}${roots.payment}/success`,
  },
};
