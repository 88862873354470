import { SubjectOverview as OrvalSubjectOverview } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

import { CourseRead, toCourseRead } from './course-read';
import { TopicCount, toTopicCount } from './topic-count';

export const SubjectOverview = zod.object({
  id: zod.number(),
  name: zod.string(),
  courseType: CourseRead,
  topics: zod.array(TopicCount),
});

export type SubjectOverview = zod.infer<typeof SubjectOverview>;

export const toQuestionStatusCount = (statusCount: OrvalSubjectOverview): SubjectOverview => {
  let result;

  const topics = statusCount.topics.map(toTopicCount);
  const courseType = toCourseRead(statusCount.course_type);

  try {
    result = SubjectOverview.parse({
      id: statusCount.id,
      name: statusCount.name,
      courseType,
      topics,
    });
  } catch (e) {
    console.log('Error parsing subject overview', e);
    throw e;
  }

  return result;
};
