import { DifficultyEnum, Question as OrvalQuestion } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

export const Question = zod.object({
  id: zod.number(),
  content: zod.string(),
  difficulty: zod.nativeEnum(DifficultyEnum),
  explanation: zod.string().nullable(),
  isCal: zod.boolean().optional(),
  order: zod.number(),
  subtopic: zod.number().nullable(),
});

export type Question = zod.infer<typeof Question>;

export const toQuestion = (subTopic: OrvalQuestion): Question => {
  let result;
  try {
    result = Question.parse({
      id: subTopic.id,
      content: subTopic.content,
      difficulty: subTopic.difficulty,
      explanation: subTopic.explanation,
      isCal: subTopic.is_cal,
      order: subTopic.order || subTopic.id,
      subtopic: subTopic.subtopic,
    });
  } catch (e) {
    console.log('Error parsing question', e);
    throw e;
  }

  return result;
};
