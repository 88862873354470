import { Subject as OrvalSubject } from 'studyvibes-api/orval/models';
import * as zod from 'zod';

import { Course, toCourse } from './course';

export const Subject = zod.object({
  name: zod.string(),
  courses: zod.array(Course),
  description: zod.string().nullable(),
  id: zod.number(),
  createdAt: zod.date(),
  updatedAt: zod.date(),
});

export type Subject = zod.infer<typeof Subject>;

export const toSubject = (subject: OrvalSubject): Subject => {
  let result;
  try {
    result = Subject.parse({
      name: subject.name,
      courses: subject.course_type.map(toCourse),
      description: subject.description,
      id: subject.id,
      createdAt: new Date(subject.created_at),
      updatedAt: new Date(subject.updated_at),
    });
  } catch (e) {
    console.log('Error parsing subject', e);
    throw e;
  }

  return result;
};
