/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `SL` - StandardLevel
 * `HL` - HighLevel
 */
export type LevelEnum = (typeof LevelEnum)[keyof typeof LevelEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LevelEnum = {
  SL: 'SL',
  HL: 'HL',
} as const;
